import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  ProposalsSystem: [
    {
      ranksvg: require('@/assets/images/icons/bx-down-arrow.svg'),
      rank: 1,
      productName: 'محصول 1',
      productType: 'نوع محصول 1',
      companyName: 'شرکت روندسافت',
      productBasic: 'پایه محصول',
      usage: '60',
      title: 'سید مرتضی یثربی',
      subtitle: 'ravand.vc@gmail.com',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      totalprice: '200,000',
      usersreview: '80%',
      loss: true,
    },
    {
      ranksvg: require('@/assets/images/icons/bx-up-arrow.svg'),
      rank: 4,
      productName: 'محصول 21',
      productType: 'نوع محصول 21',
      companyName: 'شرکت روندسافت',
      productBasic: 'پایه محصول',
      usage: '20',
      title: 'سید مرتضی یثربی',
      subtitle: 'ravand.vc@gmail.com',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      totalprice: '200,000',
      usersreview: '80%',
      loss: true,
    },
    {
      ranksvg: require('@/assets/images/icons/bx-down-arrow.svg'),
      rank: 2,
      productName: 'محصول 5',
      productType: 'نوع محصول 5',
      companyName: 'شرکت روندسافت',
      productBasic: 'پایه محصول',
      usage: '10',
      title: 'سید مرتضی یثربی',
      subtitle: 'ravand.vc@gmail.com',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      totalprice: '200,000',
      usersreview: '80%',
      loss: true,
    },
    {
      ranksvg: require('@/assets/images/icons/bx-down-arrow.svg'),
      rank: 3,
      productName: 'محصول 2',
      productType: 'نوع محصول 2',
      companyName: 'شرکت روندسافت',
      productBasic: 'پایه محصول',
      usage: '600',
      title: 'سید مرتضی یثربی',
      subtitle: 'ravand.vc@gmail.com',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      totalprice: '200,000',
      usersreview: '80%',
      loss: true,
    },
  
  ],
}
/* eslint-disable global-require */
mock.onGet('/proposals/system/data').reply(() => [200, data])
