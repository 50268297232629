import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  ProposalsTable: [
    {
      project: 'نام پروژه 1',
      productType: 'بتن',
      amount: '10 تن',
      budget: '1 میلیارد تومان',
    },
    {
      project: 'نام پروژه 2',
      productType: 'بتن',
      amount: '15 تن',
      budget: '1 میلیارد تومان',
    },

  ],
}
/* eslint-disable global-require */
mock.onGet('/proposals/data').reply(() => [200, data])
