import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  ContractTable: [
    {
      fullname: 'سیدمرتضی یثربی',
      typebrand: 'سواری - ایران خودرو',
      plaque: '22 ه 658',
      InquiryCar: 'دارد',
      InquiryDriver: 'ندارد',
      rate: '4.8',
    },

  ],
  LastContractTable:[
    {
      fullname: 'سیدمرتضی یثربی',
      typebrand: 'سواری - ایران خودرو',
      plaque: '22 ه 658',
      InquiryCar: 'دارد',
      InquiryDriver: 'ندارد',
      rate: '4.8',
    }, 
  ],
}
/* eslint-disable global-require */
mock.onGet('/contract/data').reply(() => [200, data])
